<template v-cloak>
  <el-row>
    <el-col class="login">
      <el-col class="login-condition">
        <el-col class="login-content">
          <el-col class="title">伊登云商城</el-col>
          <el-col class="title en">EDEN CLOUD SHOPPING MALL</el-col>
          <el-col class="img">
            <img src="@/img/user/login_bg_1.png">
          </el-col>
        </el-col>
        <el-col class="login-container" >
          <el-col class="container-title" v-if="toHuawei">登录华为云</el-col>
          <el-col class="container-title-en" v-if="toHuawei" >HUAWEI TO LOGIN</el-col>
          <el-col class="container-title" v-if="!toHuawei">欢迎登陆</el-col>
          <el-col class="container-title-en" v-if="!toHuawei">WELCOME TO LOGIN</el-col>
          <el-col class="line"></el-col>
          <el-col class="form" v-if="!loging">
            <el-form class="login-form" ref="loginForm" :model="loginForm" :rules="loginFormValidate">
              <el-form-item prop="userAuthAccount">
                <el-input class="login-input" placeholder="请输入邮箱...." v-model="loginForm.userAuthAccount">
                  <i slot="prefix">
                    <img src="@/img/user/icon/icon_account.svg">
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item prop="userAuthCredential">
                <el-input class="login-input" placeholder="请输入密码..." v-model="loginForm.userAuthCredential" type="password">
                  <i slot="prefix">
                    <img src="@/img/user/icon/icon_password.svg">
                  </i>
                </el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col class="condition" v-if="!loging">
            <el-col class="forget-password condition-item">忘记密码</el-col>
            <el-col class="register condition-item" @click.native="registryNow">免费注册</el-col>
          </el-col>
          <el-col class="button" v-if="!loging">
            <el-button @click="login" :loading="submitLoading">
              <i>
                <img src="@/img/user/icon/icon_btn.svg">
              </i>
            </el-button>
          </el-col>
          <el-col v-loading="loging" style="margin-top: 110px"></el-col>
        </el-col>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
import CryptoJS from 'crypto-js'
import user from '../../api/user'
import md5Js from '../../utils/md5'
import urlUtils from "../../utils/url-utils";

export default {
  name: "login",
  data() {
    return {
      loging: false,
      toHuawei: false,
      SAMLRequest: '',
      RelayState: '',
      SigAlg: '',
      Signature: '',
      submitLoading: false,
      loginForm: {
        userAuthAccount:'',
        userAuthCredential: '',
      },
      loginFormValidate: {
        userAuthAccount: [
          {required: true, message: '请输入您的账号'},
          {type:'email', message: '邮箱格式不正确', teigger:'blur'}
        ],
        userAuthCredential: [
          {required: true, message: '请输入密码'}
        ]
      }
    }
  },
  mounted() {
    this.loginHuawei()
    if(this.$store.state.userInfo.userInfo !=='' && this.toHuawei) {
      this.loging = true
    }

  },
  created() {
    this.getCookie();
      this.enterLogin();
  },
  methods: {
    loginHuawei() {
      // 获取重定向参数
      this.SAMLRequest = this.$urlUtils.getUrlKey('SAMLRequest')
      this.RelayState = this.$urlUtils.getUrlKey('RelayState')
      this.SigAlg = this.$urlUtils.getUrlKey('SigAlg')
      this.Signature = this.$urlUtils.getUrlKey('Signature')
      // 判断来源是否是华为
      if (this.SAMLRequest !== null && this.RelayState !== null){
        // 验签是否为非法请求
        let params = new URLSearchParams();
        params.append('SAMLRequest', this.SAMLRequest)
        params.append('RelayState', this.RelayState)
        params.append('SigAlg', this.SigAlg)
        params.append('Signature', this.Signature)
        params.append('userId', 'this.userId')
        user.verification(params).then((res) => {
          if (res.code === 200){
            // 判断当前实付登录
            if (localStorage.getItem('accessToken')){
              this.userId = this.$store.state.userInfo.userInfo.userId
              user.redirect(params).then((res) => {
                let myWindow=window.open();
                myWindow.document.write(res) //info为html的字符串
                myWindow.document.close()//必须关闭流，否则表单不生效
                myWindow.focus();
                window.close()
              })
            }else {
              this.toHuawei =  true;
            }

          }
        })

      }
    },
      enterLogin() {
          document.onkeydown = e => {
              if (e.keyCode === 13) {
                  this.login();
              }
          }
      },

      login() {
      this.submitLoading = true;
      this.$refs["loginForm"].validate((valid) => {
        if(valid) {
          let obj = this;
          let apiData = {
            email: obj.loginForm.userAuthAccount,
            password: md5Js.hex_md5(obj.loginForm.userAuthCredential.trim()),
          }
          obj.submitLoading = true;
          user.login({'email':apiData.email, 'password':apiData.password}).then(res => {
            if (res.code == 200) {
              // 账号和密码存到cookie
              obj.setCookie(apiData.email, obj.loginForm.userAuthCredential)
              // 过期时间戳
              var expireTime = Date.now() + 1000 * 60 * 59
              // 存储token
              localStorage.setItem("accessToken",res.textValue,expireTime);
              // 存储用户信息
              this.$store.commit('setUserInfo',res.data);

              obj.$message.success(res.message);
              if (this.toHuawei){
                this.loginHuawei()
                return;
              }
              setTimeout(() => {
                // parent.document.location.href = '/#/homeIndex';
                this.$router.push("/homeIndex").catch(() => {});
              },2000);
            } else {
              // obj.clearCookie()
              obj.$message.error(res.message);
              obj.submitLoading = false;
            }
          })
        } else {
          this.submitLoading = false;
        }
      })
    },
   registryNow() {
      this.$router.push("/register").catch(() => {});
    },
    // 储存表单信息
    setUserInfo: function () {
      var self = this;
      // 判断用户是否勾选记住登录名，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (self.loginForm.remember) {
        self.setCookie(self.loginForm.userAuthAccount, self.loginForm.userAuthCredential);
      } else {
        self.clearCookie();
      }
    },
    /*
      * set 存储方法
      * @ param {String} 	key 键
      * @ param {String} 	value 值，
      * @ param {String} 	expired 过期时间，以分钟为单位，非必须
      */
    set(key, value, expired) {
      let source = this.source;
      source[key] = JSON.stringify(value);
      if (expired){
        source[`${key}__expires__`] = Date.now() + 1000*60*expired
      }
      return value;
    },
    setCookie(portId, psw, exdays) {
      // Encrypt，加密账号密码
      var cipherPortId = CryptoJS.AES.encrypt(
          portId + '',
          'secretkey1234567'
      ).toString()
      var cipherPsw = CryptoJS.AES.encrypt(psw + '', 'secretkey1234567').toString()
      console.log(cipherPortId + '/' + cipherPsw)// 打印一下看看有没有加密成功

      var exdate = new Date() // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      // 字符串拼接cookie，为什么这里用了==，因为加密后的字符串也有个=号，影响下面getcookie的字符串切割，你也可以使用更炫酷的符号。
      window.document.cookie =
          'currentPortId' +
          '==' +
          cipherPortId +
          ';path=/;expires=' +
          exdate.toGMTString()
      window.document.cookie =
          'password' +
          '==' +
          cipherPsw +
          ';path=/;expires=' +
          exdate.toGMTString()
    },
    // 读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split(';') // 这里显示的格式请根据自己的代码更改
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('==') // 根据==切割
          // 判断查找相对应的值
            let key = arr2[0].trim();
          if (key === 'currentPortId') {
            // Decrypt，将解密后的内容赋值给账号
            const bytes = CryptoJS.AES.decrypt(arr2[1], 'secretkey1234567')
            this.loginForm.userAuthAccount = bytes.toString(CryptoJS.enc.Utf8)
          } else if (key === 'password') {
            // Decrypt，将解密后的内容赋值给密码
            const bytes = CryptoJS.AES.decrypt(arr2[1], 'secretkey123')
            this.loginForm.userAuthCredential = bytes.toString(CryptoJS.enc.Utf8)
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie('', '', -1)
    }

  },
}
</script>

<style lang="less" scoped>
  .login {
    background: url("../../img/user/login_bg.png") no-repeat top center;
    height: 100vh;
    padding-top: 194px;
    .login-condition {
      width: 1200px;
      float: unset;
      margin: auto;
    }

    .login-content {
      padding-top: 87px;
      width: 510px;
      .title {
        font-size: 40px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
      }
      .en {
        font-size: 26px;
        width: auto;
        margin-top: 16px;
      }

      .img {
        margin-top: 26px;
        width: auto;
      }
    }
    .login-container {
      /deep/ .el-loading-spinner .circular {
        height: 60px;
        width: 60px;
      }
      width: 400px;
      height: 500px;
      background: #FFFFFF;
      box-shadow: 0px 0px 23px 0px rgb(126, 126, 126, 85%);
      border-radius: 15px;
      padding: 55px 56px;
      float: right;

      .container-title {
        font-size: 24px;
        font-weight: bold;
        color: #252525;
      }

      .container-title-en {
        font-size: 20px;
        color: rgba(37, 37, 37, 0.3);
        margin-top: 16px;
      }

      .line {
        width: 28px;
        height: 5px;
        background: #252525;
        border-radius: 3px;
        margin-top: 16px;
        margin-bottom: 32px;
      }

      .login-form {

        .el-form-item:last-child {
          margin-bottom: 32px;
        }

        .el-form-item {
          margin-bottom: 24px;

          /deep/ .el-form-item__content {
            line-height: 40px;
          }

        }

        .login-input {
          /deep/ input {
            height: 42px;
            border-radius: 10px;
            border: 1px solid rgba(37,37,37,0.5000);
          }

          /deep/.el-input__inner {
            padding-left: 61px;
          }
        }
        img {
          width: 20px;
          height: 24px;
          margin-top: 9px;
          padding-left: 17px;
        }
      }

      .condition {

        .condition-item:hover {
          cursor: pointer;
        }

        .condition-item {
          font-size: 14px;
          width: auto;
        }

        .forget-password {
          color: #D60010;
        }

        .register {
          float: right;
          color: #414141;
        }
      }

      .button {
        height: 39px;
        //background: #252525;
        //border-radius: 20px;
        margin-top: 32px;
        //padding: 15px 134px;

        .el-button {
          width: 100%;
          height: 39px;
          background: #252525;
          border-radius: 20px;

          /deep/ .el-icon-loading {
            color: #FFFFFF;
          }
        }

        .icon-btn {
          background: url("../../img/user/icon/icon_btn.svg") no-repeat top center;
          width: 24px;
          height: 10px;
        }
        .icon-btn:before {
          content: ' ';

        }
      }

    }
  }

</style>
